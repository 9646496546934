<template>
  <!-- 移动端 -->
  <div class="mobile—login" v-if="isMobile">
    <div class="header">
      <img src="../assets/imgs/logo.png" style="height: 100px" alt="" />
    </div>
    <div class="content">
      <div class="input-box">
        账号
        <van-field v-model="form.username" placeholder="请输入账号" />
      </div>
      <div class="input-box">
        密码
        <van-field
          v-model="form.password"
          type="password"
          placeholder="请输入密码"
        />
      </div>
    </div>
    <div class="input-box">
      <div class="set" @click="sign()">注册账号</div>
      <div class="reset" @click="reset()">忘记密码</div>
    </div>
    <div class="footer">
      <div class="login gradient-background" @click="login">
        确定<i v-if="loading" class="el-icon-loading"></i>
      </div>
    </div>
  </div>
  <!-- PC端 -->
  <div class="pc-login" v-else>
    <!-- 登录框 -->
    <div class="login-box">
      <el-card class="box-card">
        <div class="header">
          <img src="../assets/imgs/logo.png" alt="" />
        </div>
        <div class="content">
          <el-form
            ref="loginForm"
            :model="form"
            :rules="rules"
            label-width="100px"
            label-position="top"
          >
            <el-form-item label="账号" prop="username">
              <el-input
                v-model="form.username"
                placeholder="请输入账号"
              ></el-input>
            </el-form-item>
            <el-form-item label="密码" prop="username">
              <el-input
                v-model="form.password"
                type="password"
                placeholder="请输入密码"
              ></el-input>
            </el-form-item>
          </el-form>
          <div class="input-box">
            <div class="set" @click="sign()">注册报名</div>
            <div class="reset" @click="reset()">忘记密码</div>
          </div>
          <!-- <div class="reset" @click="reset()">忘记密码</div> -->
        </div>
        <div class="footer">
          <div class="login gradient-background" @click="checkLogin">
            登录<i v-if="loading" class="el-icon-loading"></i>
          </div>
        </div>
      </el-card>
    </div>

    <!-- 弹框 -->
    <el-dialog v-model="dialogInfo.show" width="500px" :show-close="false">
      <!-- 忘记密码修改密码 -->
      <div class="reset-dialog" v-if="dialogInfo.status == 1">
        <div class="dialag-title">请填写您的修改密码信息</div>
        <el-form
          ref="loginForm"
          :model="form"
          :rules="rules"
          label-width="100px"
          label-position="top"
        >
          <el-form-item label="手机号码" prop="username">
            <el-input
              v-model="form.mobile"
              placeholder="请输入手机号码"
              onkeyup="this.value=this.value.replace(/\D/g,'')"
            ></el-input>
          </el-form-item>
          <el-form-item label="身份证" prop="username">
            <el-input
              v-model="form.idCard"
              type="password"
              placeholder="请输入身份证"
              onkeyup="this.value=this.value.replace(/\D/g,'')"
            ></el-input>
          </el-form-item>
        </el-form>

        <div class="confirm gradient-background" @click="btns(true)">确定</div>
      </div>
      <!-- 设置密码成功 -->
      <div class="reset-result" v-if="dialogInfo.status == 2">
        <div class="result gradient-background">
          <i class="el-icon-check"></i>
        </div>
        <p>修改密码成功</p>
        <div
          class="confirm gradient-background"
          @click="dialogInfo.show = false"
        >
          立即登录
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  name: "login",
  data() {
    return {
      form: {
        username: "",
        password: "",
      },
      isMobile: false,
      loading: false,
      dialogInfo: {
        show: false, // 显示弹框
        status: "", //  0提示修改初始密码，1设置新密码，2忘记密码修改密码，3修改密码成功
        idCard: "",
        mobile: "",
      },
      rules: {
        username: [{ required: true, message: "请输入帐号", trigger: "blur" }],
        password: [{ required: true, message: "请输入密码", trigger: "blur" }],
        idCard: [{ required: true, message: "请输入身份证", trigger: "blur" }],
        mobile: [
          { required: true, message: "请输入手机号码", trigger: "blur" },
        ],
      },
    };
  },
  computed: {},
  created() {
    const userInfo = localStorage.getItem("userInfo");
    this.isMobile = this._isMobile();
    if (userInfo) {
      this.$router.push({
        path: this._isMobile() ? "/mobileHome" : "/home",
      });
    }
  },
  methods: {
    sign() {
      //this.$router.push({ path: "/register" });
      // this.$router.push({ path: "/signUp" });
    },
    reset() {
      // if (this._isMobile()) {
      //   this.$router.push({ path: "/operation" });
      // } else {
      //   this.dialogInfo.show = true;
      //   this.dialogInfo.status = 1;
      // }
    },
    checkLogin() {
      if (this.loading) return;
      if (this._isMobile()) {
        this.login();
      } else {
        this.$refs.loginForm.validate((valid) => {
          if (valid) {
            this.login();
          } else {
            return false;
          }
        });
      }
    },
    login() {
      this.loading = true;
      this.$https
        .post("/api/login", {
          mobile: this.form.username,
          password: this.form.password,
        })
        .then((res) => {
          this.loading = false;
          console.log(res);
          if (res.success) {
            const data = res.data;
            console.log(this._isMobile() ? "/mobileHome" : "/home");
            localStorage.setItem("userInfo", JSON.stringify(data));
            if (this._isMobile()) {
              this.$router.push({
                path: "/mobileHome",
              });
              console.log("/mobileHome");
            } else {
              this.$router.push({
                path: "/home",
              });

              console.log("/home");
            }
          } else {
            this.loading = false;
            this.$message.error(res.message);
          }
        })
        .catch((error) => {
          this.loading = false;
          this.$message.error(error.message || "账号或者密码错误");
        });
    },
  },
};
</script>
<style lang="less" scope>
// 移动端
.mobile—login {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 50px 40px;
  font-size: 20px;
  .header {
    text-align: center;
    margin-bottom: 100px;
  }
  .van-cell {
    line-height: 50px;
    padding: 0 10px;
    border: 1px solid #b0b9ca;
    border-radius: 5px;
    font-size: 16px;
  }
  .set {
    display: inline-block;
    top: 55px !important;
    line-height: 20px !important;
    cursor: pointer;
    right: 2px;
    color: #b0b9ca;
    font-size: 12px;
    border-bottom: 1px solid #b0b9ca;
  }
  .reset {
    display: inline-block;
    position: absolute;
    top: 0;
    line-height: 20px !important;
    cursor: pointer;
    right: 2px;
    color: #b0b9ca;
    font-size: 12px;
    border-bottom: 1px solid #b0b9ca;
  }
  .footer {
    margin-top: 50px;
    .login {
      line-height: 50px;
    }
  }
  .input-box {
    font-size: 20px;
    margin-top: 2%;
    position: relative;
    line-height: 40px;
    color: #677897;
  }
}

.pc-login {
  position: relative;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  -webkit-background-size: 100% 100%;
  -o-background-size: 100% 100%;
  background-image: url(../assets/imgs/background.png);
  .login-box {
    position: absolute;
    width: 400px;
    height: 50%;
    top: 20%;
    right: 10%;
    .box-card {
      padding: 20px;
      background: #f6f9fd;
      .header {
        margin-top: 5%;
        text-align: center;
        img {
          width: 200px;
        }
      }
      .content {
        margin-top: 1%;
        position: relative;
        .set {
          cursor: pointer;
          position: absolute;
          left: 10px;
          color: #b0b9ca;
          line-height: 15px;
          font-size: 12px;
          border-bottom: 1px solid #b0b9ca;
        }
        .reset {
          cursor: pointer;
          position: absolute;
          right: 10px;
          color: #b0b9ca;
          line-height: 15px;
          font-size: 12px;
          border-bottom: 1px solid #b0b9ca;
        }
      }
      .footer {
        padding: 5% 0;
        .login {
          cursor: pointer;
          border-radius: 5px;
          text-align: center;
          line-height: 40px;
          color: #fff;
          font-size: 14px;
        }
      }
    }
  }
}

.dialag-title {
  text-align: center;
  font-weight: bold;
  font-size: 16px;
  color: #677897;
}

.confirm {
  margin-top: 5%;
  cursor: pointer;
  border-radius: 5px;
  text-align: center;
  line-height: 40px;
  color: #fff;
}

// 覆盖element样式
.el-dialog {
  border-radius: 5px !important;
}
.el-dialog__header {
  background-color: #f6f9fd;
  border-radius: 5px !important;
}
.el-dialog__body {
  padding: 0 20px 30px 20px !important;
  background-color: #f6f9fd;
  border-radius: 5px !important;
}
.el-dialog__title {
  color: #677897 !important;
}
.el-form--label-top .el-form-item__label {
  color: #677897;
  padding: 0;
}
.el-form-item {
  margin-bottom: 10px;
}
.el-form-item.is-required:not(.is-no-asterisk)
  .el-form-item__label-wrap
  > .el-form-item__label:before,
.el-form-item.is-required:not(.is-no-asterisk) > .el-form-item__label:before {
  display: none;
}
</style>
